/* eslint-disable no-console */

import { register } from 'register-service-worker'

// import axios from "../src/api/axios";
// 公钥
const publicKey = 'BO_sjITRaeBOaC5UDMb6L3_h64FMRozOAgct02jsKcfjvM6SuKcJjQTMXBBGM5H3xhT1u-Oz11_Gi1yC8RDsin4';
window.register = register;
register(`${process.env.BASE_URL}service-worker.js`, {
  ready(registration) {
    console.log(
      'App is being served from cache by a service worker.\n' +
      'For more details, visit https://goo.gl/AFskqB'
    )


    // 订阅web push服务，成功后提交endpoint至服务端保存
    // const convertedVapidKey = urlBase64ToUint8Array(publicKey);
    // const subscribeOption = {
    //   userVisibleOnly: true,
    //   applicationServerKey: convertedVapidKey,
    // }
    // registration.pushManager.subscribe(subscribeOption).then(endpoint => {
    //   提交endpoint
    //   axios.post('endpoint', endpoint).then(res => {
    //     console.log('save push endpoint result, ' + JSON.stringify(res))
    //   })
    // })


  },
  registered() {
    console.log('Service worker has been registered.')
  },
  cached() {
    console.log('Content has been cached for offline use.')
  },
  updatefound() {
    console.log('New content is downloading.')
  },
  updated() {
    console.log('New content is available; please refresh.')
  },
  offline() {
    console.log('No internet connection found. App is running in offline mode.')
  },
  error(error) {
    console.error('Error during service worker registration:', error)
  }
})

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const title = 'PWA-Book-Demo Notification Title'
const options = {
  body: 'Simple piece of body text.\nSecond line of body text :)'
}
// const notification = new Notification(title, options)
// if (Notification.permission === 'granted') {
//   console.log('用户已授权，可展示通知');
//   // 用户已授权，可展示通知
// } else if (Notification.permission === 'denied') {
//   console.log('用户已禁止');
//   // 用户已禁止
// } else {

//   Notification.requestPermission().then(permission => {
//     // 通过 permission 判断用户的选择结果
//     console.log(permission);
//   })
//   console.log('用户尚未授权');
//   // 用户尚未授权，需首先向用户申请通知权限
// }

